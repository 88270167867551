<template>
  <div class="certificate__card" id="certificate" v-if="certificateDetail">
    <b-row no-gutters class="justify-content-center m-0">
      <b-col cols="12" class="certificate__head--wrapper">
        <b-row no-gutters class="certificate__head m-0">
          <b-col cols="12" lg="3" md="3">
            <div class="ch-logo">
              <img
                :src="
                  require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
                "
                alt="WHO Academy"
                height="48"
              />
            </div>
          </b-col>
          <b-col cols="12" lg="6" md="6">
            <div class="ch-badge__header">
              <img
                src="@/assets/images/certificates/certificate-badge.svg"
                alt="certificate badge"
                width="68"
                height="68"
              />
              <h5>{{ $t("achievements.certificate.title") }}</h5>
              <p>
                {{ $t("achievements.certificate.recognition_of_learning") }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <div class="certificate__card--content">
        <h3>
          {{ certificateDetail.display_name }}
        </h3>
        <p class="cc__content--desc">
          {{ $t("my_achievements.detail.certificate.certify") }}
          <span>
            {{
              certificateDetail.name
                ? certificateDetail.name
                : getAuthProfile.name
            }}</span
          >
          {{ $t("my_achievements.detail.certificate.successfully_completed") }}
          <span>{{ certificateDetail.display_name }}</span>
          {{ $t("my_achievements.detail.certificate.from") }}
          <span>{{ certificateDetail.org }}</span>
          {{ $t("my_achievements.detail.certificate.demonstrated") }}
        </p>
        <p class="cc__content--desc">
          {{ $t("my_achievements.detail.certificate.composed") }}
          <span>{{ certificateDetail.module_level_credentials.length }}</span>
          {{ $t("my_achievements.detail.certificate.proof") }}
        </p>
        <p class="cc__content--issued">
          {{ $t("my_achievements.detail.certificate.issued_on") }}
          {{ certificateDetail.created_date | formatDate("MMMM Do YYYY") }}
        </p>
      </div>
      <div class="certificate__card--disclaimer">
        <div class="cc-disclaimer--div">
          <img
            :src="require(`@/assets/images/certificates/disclaimer.svg`)"
            alt="badge"
            width="12"
            height="13"
          />
          <p>
            <span class="text-uppercase">
              {{ $t("achievements.microcredential.disclaimer.title") }}:
            </span>
            {{ $t("achievements.microcredential.disclaimer.content") }}
          </p>
        </div>
      </div>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import getLogo from "@/core/mixins/getLogo";

export default {
  mixins: [getLogo],
  computed: {
    ...mapGetters(["getAuthProfile", "certificateDetail"])
  },
  mounted() {
    setTimeout(() => {
      this.$i18n.locale = this.language;
      this.$store.commit("SET_LANGUAGE", this.language);
    }, 50);
  }
};
</script>
<style lang="scss" scoped>
.certificate__card {
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  border-radius: 8px;
  overflow: hidden;
  min-height: 484px;
  display: flex;
  height: 842px;
  margin-bottom: 24px;

  .certificate__head--wrapper {
    height: 192px;
    .certificate__head {
      background: $brand-primary-100;
      padding: 29px 40px 16px;
      border-radius: 8px;
      height: 192px;

      .ch-badge__header {
        text-align: center;
        h5 {
          @include h5($brand-primary-400);
        }
        p {
          @include subtitle-regular;
          color: $brand-primary-400;
        }
      }
    }
  }
  .certificate__card--content {
    text-align: center;
    padding: 0 41px;
    h3 {
      @include subtitle-large($brand-neutral-900);
      margin-bottom: 24px;
    }
    .cc__content--desc {
      @include body-regular;
      margin-bottom: 24px;
      padding: 0 50px;
      span {
        font-weight: 500;
        color: $brand-neutral-900;
      }
    }
    .cc__content--issued {
      @include label-large($brand-neutral-300, 500);
      align-items: center;
      margin-bottom: 16px;
    }
  }

  .certificate__card--disclaimer {
    display: flex;
    align-items: flex-end;
    text-align: left;
    margin-bottom: 16px;
    padding: 0 41px;
    .cc-disclaimer--div {
      @include flex-horizontal-center;
      img {
        margin: 3px 10px 0 0;
      }
      p {
        font-size: 11px;
        font-weight: 400;
        line-height: 13.5px;
        letter-spacing: 0.1px;
        color: $brand-neutral-300;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .credential__card {
    margin: 40px 16px 100px !important;

    .credential__head {
      background: $brand-secondary2-50;
      padding: 15px 15px 8px;
    }

    .credential__body {
      .cb__title {
        margin-top: 12px;
      }
    }

    .cb__content-description {
      padding: 0px 8px 8px 0px;

      &.valid-for {
        padding: 0;
      }
    }

    .ch-logo {
      text-align: left;
    }
  }
}

[dir="rtl"] {
  .credential__card {
    .cb-disclaimer {
      img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .cb__details-content {
      padding: 8px 8px 8px 0;

      img {
        margin-right: 0px;
        margin-left: 10px;
      }
    }

    .cb__details-content,
    .cb__content-title,
    .cb__content-description,
    .cb-disclaimer {
      text-align: right;
    }
  }
}
</style>
